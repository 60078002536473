import BaseProvider from '@/providers/baseprovider';

export type signalrCall = 'all' | 'item' | 'customer' | 'matrix' |  'item_category' | 'item_level' |
    'price_list' | 'global_user' | 'proposition' | 'printer' | 'store_printer' | 'rank' | 'timeslot_template';

export const signalrCalls: string[] = [
    'all' , 'item' , 'customer' , 'matrix' ,  'item_category' , 'item_level' ,
    'price_list' , 'global_user' , 'proposition' , 'printer' , 'store_printer' , 'rank', 'timeslot_template'
];

export default class SignalRProvider extends BaseProvider {
    public async pushDownloadRequest(
        call: signalrCall,
        entityIds?: number[],
        storeIds?: number[]
    ): Promise<void> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return await (await this.getApiV2()).post(`/signalr/pushdownloadrequest/${call}${p}`);
    }
}
