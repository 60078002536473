import Store from '@/models/store'
import { Timeslot } from '@/models/timeslot'
import { TimeslotDay } from '@/models/timeslot/TimeslotDay'
import { TimeslotDayRule } from '@/models/timeslot/TimeslotDayRule'
import { useTimeslotProvider } from '@/providers/TimeslotProvider'
import useTimeslotFormState, {
  timeslotDayRuleDefaultState,
  timeslotFormDefaultState
} from '@/states/timeslots/details/form'
import { defineStore } from 'pinia'
import { reactive } from 'vue'

export type TimeslotState = {
  loading: boolean,
  selectedStore: Store | null,
  timeslot: Timeslot | null,
  selectedRuleUid: string | null,
}

export const timeslotDefaultState = (): TimeslotState => ({
  loading: false,
  selectedStore: null,
  timeslot: null,
  selectedRuleUid: null
})

const ensureDayAsCorrectDefaultRuleValues = (day: TimeslotDay): TimeslotDay => ({
  ...day,
  active: !!day.rules.length
})

const useTimeslotState = defineStore('timeslot', {
  state: timeslotDefaultState,
  getters: {
    getSelectedRule(state: TimeslotState): TimeslotDayRule {
      const defaultEmptyRule: TimeslotDayRule = timeslotDayRuleDefaultState()

      if (!state.timeslot || !state.selectedRuleUid) {
        return defaultEmptyRule
      }

      return [
        ...state.timeslot.monday.rules,
        ...state.timeslot.tuesday.rules,
        ...state.timeslot.wednesday.rules,
        ...state.timeslot.thursday.rules,
        ...state.timeslot.friday.rules,
        ...state.timeslot.saturday.rules,
        ...state.timeslot.sunday.rules
      ].find((rule: TimeslotDayRule) => rule.uid === state.selectedRuleUid) ?? defaultEmptyRule
    },
    getDayOfSelectedRule(state: TimeslotState): keyof Pick<Timeslot, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'> | undefined {
      if (!state.timeslot || !state.selectedRuleUid) {
        return
      }

      const keysToMap: Array<keyof Pick<Timeslot, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>> = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ]

      for (const day of keysToMap) {
        if (state.timeslot[day].rules.some((rule: TimeslotDayRule) => rule.uid === state.selectedRuleUid)) {
          return day
        }
      }
    }
  },
  actions: {
    async setSelectedStore(value: Store): Promise<void> {
      this.selectedStore = value
      this.selectedRuleUid = null
      await this.fetchTimeslotByStoreUid()
    },
    async fetchTimeslotByStoreUid(): Promise<Timeslot | undefined> {
      const provider = useTimeslotProvider()
      const formState = useTimeslotFormState()

      if (!this.selectedStore) {
        return
      }

      this.loading = true

      try {
        const response = await provider.fetchTimeslotByStoreUid(this.selectedStore.uid)

        if (!response) {
          this.$reset()
          formState.$reset()

          return
        }

        const timeslot: Timeslot = {
          ...response,
          monday: ensureDayAsCorrectDefaultRuleValues(response.monday),
          tuesday: ensureDayAsCorrectDefaultRuleValues(response.tuesday),
          wednesday: ensureDayAsCorrectDefaultRuleValues(response.wednesday),
          thursday: ensureDayAsCorrectDefaultRuleValues(response.thursday),
          friday: ensureDayAsCorrectDefaultRuleValues(response.friday),
          saturday: ensureDayAsCorrectDefaultRuleValues(response.saturday),
          sunday: ensureDayAsCorrectDefaultRuleValues(response.sunday),
          exceptions: {
            production: response?.exceptions?.production ?? [],
            closing: response?.exceptions?.closing ?? []
          }
        }

        this.timeslot = timeslot
        formState.value = reactive(timeslot)

        return response
      } catch (_) {
        const defaultValue: Timeslot = timeslotFormDefaultState().value

        this.timeslot = defaultValue
        formState.value = reactive(defaultValue)
        //
      } finally {
        this.loading = false
      }
    }
  }
})

export default useTimeslotState
