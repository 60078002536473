import {RegimeType} from '@/models/enums/regimetype.enum';

export interface SupplierEntity {
    entity_id: number;
    accounting_id?: string;
    accounting_uid?: string;
}

export class SupplierAddress {
    id?: number;
    uid?: string;
    name?: string;
    address_line?: string;
    address_line2?: string;
    zip_code?: string;
    city?: string;
    country_id?: number;
    country?: string;
}
export default class Supplier {
    [key: string]: any;
    public uid?: string;
    public name?: string;
    public language?: string;
    public email?: string;
    public email_technical?: string;
    public phone_number?: string;
    public website?: string;
    public active?: boolean = true;
    public entities?: SupplierEntity[] = [];
    public addresses?: SupplierAddress[] = [];
    public iban?: string;
    public bic?: string;
    public duty_active: boolean = false;
    public vat_number?: string;
    public regime_type: number = RegimeType.Normal;
    public payment_term_id?: number;
    public note?: string;
    public warning?: string;
    public administrative_address_line?: string = '';
    public administrative_address_line2?: string;
    public administrative_zip_code?: string = '';
    public administrative_city: string = '';
    public administrative_country_id: number = 56;

    // Zephyr properties
    public webshop_id?: number
    public webshop_active?: boolean = true

    constructor(init?: Partial<Supplier>) {
        Object.assign(this, init);
    }
}
