import { TimeslotSchedulerEvent } from '@/components/Timeslot/Scheduler/TimeslotScheduler.vue'
import { TimeslotChannels } from '@/models/timeslot/TimeslotChannels'
import moment from 'moment'

export type TimeslotDayRule = {
  uid: string,
  active: boolean,
  maximum_orders: number | null,
  maximum_units: number | null,
  from_time: string,
  to_time: string,
  channels: TimeslotChannels
}

const remapRuleToSchedulerEvent = (rule: TimeslotDayRule, day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'): TimeslotSchedulerEvent => {
  let dayOfWeek: number = 0

  switch (day) {
    case 'monday':
      dayOfWeek = 1
      break
    case 'tuesday':
      dayOfWeek = 2
      break
    case 'wednesday':
      dayOfWeek = 3
      break
    case 'thursday':
      dayOfWeek = 4
      break
    case 'friday':
      dayOfWeek = 5
      break
    case 'saturday':
      dayOfWeek = 6
      break
    default:
      dayOfWeek = 7
      break
  }

  return {
    startDate: remapToDateOfWeekDay(dayOfWeek, rule.from_time),
    endDate: remapToDateOfWeekDay(dayOfWeek, rule.to_time),
    disabled: !rule.active,
    rule
  }
}

const remapToDateOfWeekDay = (dayOfWeek: number, time: string): Date => {
  const [ hours, minutes ] = time.split(':')

  return moment()
    .day(dayOfWeek)
    .set('hours', Number(hours))
    .set('minutes', Number(minutes))
    .set('seconds', 0)
    .toDate()
}

export default remapRuleToSchedulerEvent