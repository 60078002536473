import { Timeslot } from '@/models/timeslot'
import BaseProvider from '@/providers/baseprovider'
import { TimeslotDetailFormValues } from '@/types/detail-form/TimeslotDetailFormValues'
import { v4 } from 'uuid'

export default class TimeslotProvider extends BaseProvider {
  public async create(timeslot: TimeslotDetailFormValues): Promise<Timeslot> {
    const axiosInstance = await this.getApiV2()
    const response = await axiosInstance.post<Timeslot>(`/TimeslotTemplates`, {
      ...timeslot,
      uid: v4()
    })

    return response.data
  }

  public async update(timeslotUid: string, timeslot: TimeslotDetailFormValues): Promise<Timeslot> {
    const axiosInstance = await this.getApiV2()
    const response = await axiosInstance.put<Timeslot>(`/TimeslotTemplates/${timeslotUid}`, timeslot)

    return response.data
  }

  public async fetchTimeslotByStoreUid(storeUid: string): Promise<Timeslot | undefined> {
    const axiosInstance = await this.getApiV2()
    const response = await axiosInstance.get<Timeslot>(`/Stores/${storeUid}/TimeslotTemplate`)

    return response.data
  }
}

export function useTimeslotProvider() {
  return new TimeslotProvider()
}