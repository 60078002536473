import BaseProvider from '@/providers/baseprovider';
import { PagedParams } from '@/models/paged/pagedparams';
import PagedPurchases from '@/models/paged/pagedpurchases';
import ItemStore from '@/models/itemstore';
import ItemProvider from './itemprovider';
import { PurchaseRepository } from '@/models/repository/purchaserepository';
import { ICommercialLineTransfer } from '@/stores/commercial';
import Sale from '@/models/sale';
import moment from 'moment';

export default class PurchaseProvider extends BaseProvider {
    public async fetchPagedPurchases(
        entityIds: number[],
        pagedParams?: PagedParams,
        params?: {
            purchaseIds?: number[];
            storeIds?: number[];
            journalIds?: number[],
            journalTypeIds?: number[],
            flowTypeIds?: number[],
            fromDate?: Date,
            toDate?: Date
        }): Promise<PagedPurchases> {

        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'date',
            sortOrder: 'desc'
        });
        p = p.addParams("purchaseIds", params?.purchaseIds, true);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("journalIds", params?.journalIds, true);
        p = p.addParams("journalTypeIds", params?.journalTypeIds, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p !== '' ? `?${p}` : '';

        if (params?.fromDate && params?.toDate)
            p = `/${moment(params.fromDate).format('yyyy-MM-DD HH:mm:ss')}/${moment(params.toDate).format('yyyy-MM-DD HH:mm:ss')}${p}`;

        const response = await (await this.getApiV2()).get(`purchases${p}`);

        return new PagedPurchases(response.data);
    }

    public async getPurchaseJSON(purchaseHeaderUid: string): Promise<any> {
        const response = await (await this.getApiV2()).get(`/purchases/${purchaseHeaderUid}`);

        return response.data;
    }

    public async getPurchaseRepository(purchaseHeaderUid: string): Promise<PurchaseRepository> {
        const data = await this.getPurchaseJSON(purchaseHeaderUid);

        const repository = new PurchaseRepository();
        repository.fromJson(data);

        return repository;
    }

    public async save(repository: PurchaseRepository): Promise<any> {
        const json = repository.toJson();

        const response = await (await this.getApiV2()).post(`/purchases`, json);

        return response.data;
    }

    public async saveDraftTransfer(repository: PurchaseRepository, journalUid: string, lines: ICommercialLineTransfer[]): Promise<any> {
        const response = await (await this.getApiV2()).post(`/purchases/${repository.header.model.uid}/transfer/${journalUid}/draft`, {
            lines: lines.map((line) => ({
                uid: line.lineUid,
                quantity: line.quantity,
            }))
        });

        return response.data;
    }

    public async transfer(repository: PurchaseRepository, journalUid: string, lines: ICommercialLineTransfer[]): Promise<any> {
        const response = await (await this.getApiV2()).post(`/purchases/${repository.header.model.uid}/transfer/${journalUid}`, {
            lines: lines.map((line) => ({
                uid: line.lineUid,
                quantity: line.quantity,
            })),
        });

        console.log('response transfer repository', response);

        return response.data;
    }

    public async send(repository: PurchaseRepository,
        to: string[],
        subject: string,
        body: string,): Promise<any> {
        const response = await (await this.getApiV2()).post(`/purchases/${repository.header.model.uid}/send`, {
            to,
            subject,
            body,
        });

        return response.data;
    }

    public async preview(uid: string): Promise<any> {
        const response = await (await this.getApiV2()).get(`/purchases/${uid}/preview`);

        return response.data;
    }

    public async searchItems(entityIds: number[], search: string, priceListUid?: string | undefined): Promise<Array<any>> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("search", [search]);

        if (priceListUid)
            p = p.addParams("priceListUid", [priceListUid]);

        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/purchases/searchitems${p}`);

        for (let idx = 0; idx < response.data?.length ?? 0; idx++) {
            if ("stores" in response.data[idx]) {
                response.data[idx].stores.map((d: any) => new ItemStore(d));
            } else {
                response.data[idx].stores = await (new ItemProvider().fetchItemStores(response.data[idx].item_id)) ?? [];
            }
        }

        return response.data;
    }
}